/* global angular */

angular.module('smartvid').service('aconexIntegrationApi', function ($http, $q, $log, config) {
  let rootUrl = config.env.development.apiRootUrl

  this.getAconexUser = getAconexUser
  this.getAconexProjects = getAconexProjects
  this.getAconexRegions = getAconexRegions
  this.getAconexConnection = getAconexConnection

  function getAconexUser (projectId, customErrorHandler) {
    let url = `${rootUrl}/api/integration/project/${projectId}/aconex/user`
    let defer = $q.defer()

    $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
      $log.debug('get aconex user', response.data)
      defer.resolve(response.data)
    }, function (response) {
      defer.reject(response.data)
    })

    return defer.promise
  }

  function getAconexConnection (projectId, userId, externalUserId, customErrorHandler) {
    let url = `${rootUrl}/api/integration/project/${projectId}/user/${userId}/aconex/user/${externalUserId}`
    let defer = $q.defer()

    $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
      defer.resolve(response.data)
    }, function (response) {
      defer.reject(response.data)
    })

    return defer.promise
  }
  
  function getAconexProjects (projectId, customErrorHandler) {
    let url = `${rootUrl}/api/integration/project/${projectId}/aconex/project`
    let defer = $q.defer()

    $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
      $log.debug('get aconex projects', response.data)
      defer.resolve(response.data)
    }, function (response) {
      defer.reject(response.data)
    })

    return defer.promise
  }
  
  function getAconexRegions (projectId, customErrorHandler) {
    let url = `${rootUrl}/api/integration/project/${projectId}/aconex/region`
    let defer = $q.defer()

    $http.get(url, {customErrorHandler: customErrorHandler}).then((response) => {
      $log.debug('get aconex regions', response.data)
      defer.resolve(response.data.regions)
    }, function (response) {
      defer.reject(response.data)
    })

    return defer.promise
  }
})
