/* global angular */

angular
  .module('smartvid')
  .service('smartvidApi', function (
    assetsApi,
    commentsApi,
    projectsApi,
    organizationsApi,
    sharingApi,
    tagsApi,
    usersApi,
    systemApi,
    oauthClientApi,
    procoreIntegrationApi,
    integrationSettingsApi,
    searchApi,
    adminApi,
    batchApi,
    bim360FieldIntegrationApi,
    egnyteIntegrationApi,
    forgeIntegrationApi,
    oxBlueIntegrationApi,
    storageServiceIntegrationApi,
    featureSettingsApi,
    reportingApi,
    aconexIntegrationApi,
    structionSiteIntegrationApi
  ) {
    return {
      // assets
      createAsset: assetsApi.createAsset,
      updateAsset: assetsApi.updateAsset,
      getAsset: assetsApi.getAsset,
      deleteAsset: assetsApi.deleteAsset,
      deleteAssets: assetsApi.deleteAssets,
      exportAssetsWithTagInstanceCounts: assetsApi.exportAssetsWithTagInstanceCounts,
      undeleteAsset: assetsApi.undeleteAsset,
      forceDeleteAsset: assetsApi.forceDeleteAsset,
      addTagInstanceToAssets: assetsApi.addTagInstanceToAssets,
      smartTagAssets: assetsApi.smartTagAssets,
      getAssetsByIds: assetsApi.getAssetsByIds,
      getAssetImageUrl: assetsApi.getAssetImageUrl,
      getProjectAssets: assetsApi.getProjectAssets,
      getProjectAssetsCount: assetsApi.getProjectAssetsCount,
      getProjectDeletedAssets: assetsApi.getProjectDeletedAssets,
      getProjectDeletedAssetsCount: assetsApi.getProjectDeletedAssets,
      getDeletedAssetGroupMetadata: assetsApi.getDeletedAssetGroupMetadata,
      getAssetPage: assetsApi.getAssetPage,
      getAssetGroupMetadata: assetsApi.getAssetGroupMetadata,
      getS3TTL: assetsApi.getS3TTL,
      reRunSmartTaggingAsset: assetsApi.reRunSmartTaggingAsset,
      moveAssets: assetsApi.moveAssets,
      getMoveAssetsProgress: assetsApi.getMoveAssetsProgress,
      runSnrAsset: assetsApi.runSnrAsset,
      runDziAsset: assetsApi.runDziAsset,
      getAssetFrameUrl: assetsApi.getAssetFrameUrl,
      _formatMediaAssets: assetsApi._formatMediaAssets,
      verifyAllTagsForAsset: assetsApi.verifyAllTagsForAsset,
      downloadSelectedAssetsSnapshots: assetsApi.downloadSelectedAssetsSnapshots,
      createDownloadPackage: assetsApi.createDownloadPackage,
      getDownloadPackageStatus: assetsApi.getDownloadPackageStatus,
      downloadPackage: assetsApi.downloadPackage,
      getDuplicateChecksums: assetsApi.getDuplicateChecksums,

      // batch
      getProjectJobStatus: batchApi.getProjectJobStatus,
      getOrgJobStatus: batchApi.getOrgJobStatus,

      // comments
      createCommentForAsset: commentsApi.createCommentForAsset,
      updateCommentForAsset: commentsApi.updateCommentForAsset,
      deleteCommentForAsset: commentsApi.deleteCommentForAsset,
      getCommentsForAsset: commentsApi.getCommentsForAsset,

      // projects
      addUsersToProject: projectsApi.addUsersToProject,
      addUsersToProjects: projectsApi.addUsersToProjects,
      createProject: projectsApi.createProject,
      updateProject: projectsApi.updateProject,
      restoreProjects: projectsApi.restoreProjects,
      updateProjectStatus: projectsApi.updateProjectStatus,
      updateProjectsStatus: projectsApi.updateProjectsStatus,
      deleteProjects: projectsApi.deleteProjects,
      getProjectUsers: projectsApi.getProjectUsers,
      getProjectUser: projectsApi.getProjectUser,
      getProjectUserDetails: projectsApi.getProjectUserDetails,
      getUserProjects: projectsApi.getUserProjects,
      getUserDeletedProjects: projectsApi.getUserDeletedProjects,
      getUserProject: projectsApi.getUserProject,
      getProjectNotificationSettings: projectsApi.getProjectNotificationSettings,
      updateUserForProject: projectsApi.updateUserForProject,
      removeUsersFromProject: projectsApi.removeUsersFromProject,
      reinviteUserToSmartvidAndProject: projectsApi.reinviteUserToSmartvidAndProject,
      copySampleProject: projectsApi.copySampleProject,
      getCopyProjectProgress: projectsApi.getCopyProjectProgress,
      updateUsersForProject: projectsApi.updateUsersForProject,
      favoriteProject: projectsApi.favoriteProject,
      unfavoriteProject: projectsApi.unfavoriteProject,

      // organizations and users
      addUserToOrganization: organizationsApi.addUserToOrganization,
      createOrganization: organizationsApi.createOrganization,
      updateOrganization: organizationsApi.updateOrganization,
      createUser: organizationsApi.createUser,
      removeFromOrganization: organizationsApi.removeFromOrganization,
      deleteOrganization: organizationsApi.deleteOrganization,
      getOrganizationUsers: organizationsApi.getOrganizationUsers,
      getOrganizationUsersCount: organizationsApi.getOrganizationUsersCount,
      getUserOrganizations: organizationsApi.getUserOrganizations,
      removeUserFromOrganization: organizationsApi.removeUserFromOrganization,
      getUser: organizationsApi.getUser,
      autoJoinRequest: organizationsApi.autoJoinRequest,
      updateUser: organizationsApi.updateUser,
      validateOrganizationEmailDomains: organizationsApi.validateOrganizationEmailDomains,
      getOrganizationNotificationSettings: organizationsApi.getOrganizationNotificationSettings,

      // sharing
      newLink: sharingApi.newLink,
      shareContent: sharingApi.shareContent,
      getSharedTag: sharingApi.getSharedTag,
      getSharedComment: sharingApi.getSharedComment,
      shareSearch: sharingApi.shareSearch,
      getProjectForSharedAssets: sharingApi.getProjectForSharedAssets,
      getSharedSearch: sharingApi.getSharedSearch,
      getShareInfo: sharingApi.getShareInfo,
      getSharedProjects: sharingApi.getSharedProjects,
      getSharedAssets: sharingApi.getSharedAssets,
      getSharedAssetsCount: sharingApi.getSharedAssetsCount,
      getSharedAsset: sharingApi.getSharedAsset,
      getSharedAssetGroupMetadata: sharingApi.getSharedAssetGroupMetadata,

      // tags
      createAccountTag: tagsApi.createAccountTag,
      createProjectTag: tagsApi.createProjectTag,
      createOrgTag: tagsApi.createOrgTag,
      createTagInstance: tagsApi.createTagInstance,
      createTagInstanceDefAndNode: tagsApi.createTagInstanceDefAndNode,
      createProjectTagTreeNode: tagsApi.createProjectTagTreeNode,
      createOrgTagTreeNode: tagsApi.createOrgTagTreeNode,
      deleteTagNode: tagsApi.deleteTagNode,
      deleteOrgTagNode: tagsApi.deleteOrgTagNode,
      deleteTagDefinition: tagsApi.deleteTagDefinition,
      destroyTagInstance: tagsApi.destroyTagInstance,
      getProjectTags: tagsApi.getProjectTags,
      getOrgTags: tagsApi.getOrgTags,
      getProjectAndParentOrgTags: tagsApi.getProjectAndParentOrgTags,
      searchProjectTagsOrAliases: tagsApi.searchProjectTagsOrAliases,
      getTagWithAlias: tagsApi.getTagWithAlias,
      getCommonTagDefinitionsForAssets: tagsApi.getCommonTagDefinitionsForAssets,
      updateTagDefinition: tagsApi.updateTagDefinition,
      getTagDefinitionTree: tagsApi.getTagDefinitionTree,
      getTagDefinitionTreeForOrg: tagsApi.getTagDefinitionTreeForOrg,
      getCombinedTagDefinitionTreeForOrgAndProject: tagsApi.getCombinedTagDefinitionTreeForOrgAndProject,
      getTagsForAsset: tagsApi.getTagsForAsset,
      moveProjectTagNode: tagsApi.moveProjectTagNode,
      moveOrgTagNode: tagsApi.moveOrgTagNode,
      updateTagForAsset: tagsApi.updateTagForAsset,
      importTagsForProject: tagsApi.importTagsForProject,
      importTagsForOrg: tagsApi.importTagsForOrg,
      createTagExportPackageForProject: tagsApi.createTagExportPackageForProject,
      getTagExportPackageStatusForProject: tagsApi.getTagExportPackageStatusForProject,
      downloadTagExportPackageForProject: tagsApi.downloadTagExportPackageForProject,
      createTagExportPackageForOrg: tagsApi.createTagExportPackageForOrg,
      getTagExportPackageStatusForOrg: tagsApi.getTagExportPackageStatusForOrg,
      downloadTagExportPackageForOrg: tagsApi.downloadTagExportPackageForOrg,
      getAssetCountByTagDefsForProject: tagsApi.getAssetCountByTagDefsForProject,
      getAssetCountByTagDefsForOrg: tagsApi.getAssetCountByTagDefsForOrg,
      getTagInstancesCountsGroupedByTypeForProject: tagsApi.getTagInstancesCountsGroupedByTypeForProject,
      getTagInstancesCountsGroupedByTypeForOrg: tagsApi.getTagInstancesCountsGroupedByTypeForOrg,
      checkCvTagDiscoverable: tagsApi.checkCvTagDiscoverable,
      deleteTagDefinitions: tagsApi.deleteTagDefinitions,
      getProjectTagsUpdateTime: tagsApi.getProjectTagsUpdateTime,
      getOrgTagsUpdateTime: tagsApi.getOrgTagsUpdateTime,
      getProjectTagsTreeUpdateTime: tagsApi.getProjectTagsTreeUpdateTime,
      getOrgTagsTreeUpdateTime: tagsApi.getOrgTagsTreeUpdateTime,

      // user
      authenticate: usersApi.authenticate,
      getUserStatus: usersApi.getUserStatus,
      getCurrentUser: usersApi.getCurrentUser,
      refreshToken: usersApi.refreshToken,
      updateCurrentUser: usersApi.updateCurrentUser,
      getUserByEmail: usersApi.getUserByEmail,
      getSsoLink: usersApi.getSsoLink,
      login: usersApi.login,
      onAuthSuccess: usersApi.onAuthSuccess,
      loginForLink: usersApi.loginForLink,
      impersonate: usersApi.impersonate,
      joinOrganization: usersApi.joinOrganization,
      requestPasswordReset: usersApi.requestPasswordReset,
      updatePassword: usersApi.updatePassword,
      registerNewUser: usersApi.registerNewUser,
      validateEmail: usersApi.validateEmail,
      sendRegisterNewUserEmail: usersApi.sendRegisterNewUserEmail,
      sendWelcomeNewUserEmail: usersApi.sendWelcomeNewUserEmail,
      sendInvitationToSmartvidEmail: usersApi.sendInvitationToSmartvidEmail,
      resetPassword: usersApi.resetPassword,
      checkIfPasswordResetLinkExpired: usersApi.checkIfPasswordResetLinkExpired,
      updateUserInformation: usersApi.updateUserInformation,
      getUsersByPartialEmailForOrg: usersApi.getUsersByPartialEmailForOrg,
      getUsersByPartialEmailForProject: usersApi.getUsersByPartialEmailForProject,
      getUsersByPartialEmailForOrgs: usersApi.getUsersByPartialEmailForOrgs,
      getUsersByPartialEmailForProjects: usersApi.getUsersByPartialEmailForProjects,
      findAssigneeUsersByPartialEmailInProject: usersApi.findAssigneeUsersByPartialEmailInProject,
      findAssigneeUsersByPartialEmailInOrganization: usersApi.findAssigneeUsersByPartialEmailInOrganization,
      isUserLastOrgAdmin: usersApi.isUserLastOrgAdmin,
      acceptEula: usersApi.acceptEula,

      // system
      getSystemData: systemApi.getSystemData,
      getCountryStates: systemApi.getCountryStates,
      getCountries: systemApi.getCountries,

      // OAuth client
      getOAuthUrlForProvider: oauthClientApi.getOAuthUrlForProvider,
      getOAuthUserDataForProviderForCurrentUser: oauthClientApi.getOAuthUserDataForProviderForCurrentUser,
      removeOAuthDataForProviderForExternalUserId: oauthClientApi.removeOAuthDataForProviderForExternalUserId,

      // Procore integration
      getProcorePhotoSyncSettingsForProject: procoreIntegrationApi.getProcorePhotoSyncSettingsForProject,
      getProcoreCreateIssueContext: procoreIntegrationApi.getProcoreCreateIssueContext,
      listProcoreCompanies: procoreIntegrationApi.listProcoreCompanies,
      listProcoreProjects: procoreIntegrationApi.listProcoreProjects,
      listProcoreAlbums: procoreIntegrationApi.listProcoreAlbums,
      getProcoreUserProfile: procoreIntegrationApi.getProcoreUserProfile,
      saveProcorePhotoSyncSettings: procoreIntegrationApi.saveProcorePhotoSyncSettings,
      deleteProcorePhotoSyncSettings: procoreIntegrationApi.deleteProcorePhotoSyncSettings,
      enableRunOnceProjectSync: procoreIntegrationApi.enableRunOnceProjectSync,
      createProcoreObservationIssue: procoreIntegrationApi.createProcoreObservationIssue,
      createProcoreObservationIssueWithoutAttachments:
        procoreIntegrationApi.createProcoreObservationIssueWithoutAttachments,
      getProcoreObservationIssueTypes: procoreIntegrationApi.getProcoreObservationIssueTypes,
      getProcoreObservationAssignees: procoreIntegrationApi.getProcoreObservationAssignees,
      getProcoreObservationDisMembers: procoreIntegrationApi.getProcoreObservationDisMembers,
      getProcoreObservationPotentialDisMembers: procoreIntegrationApi.getProcoreObservationPotentialDisMembers,

      // Integration settings
      getIntegrationSettingsForProject: integrationSettingsApi.getIntegrationSettingsForProject,
      getIntegrationSettingsForOrganization: integrationSettingsApi.getIntegrationSettingsForOrganization,
      updateIntegrationSettingsForOrganization: integrationSettingsApi.updateIntegrationSettingsForOrganization,
      saveIntegrationSettings: integrationSettingsApi.saveIntegrationSettings,
      deleteIntegrationSettings: integrationSettingsApi.deleteIntegrationSettings,
      enableRunOnceSyncIntegrationSettingsForProject:
        integrationSettingsApi.enableRunOnceSyncIntegrationSettingsForProject,

      // BIM 360 Field
      listBim360FieldProjects: bim360FieldIntegrationApi.listBim360FieldProjects,
      loginInToBim360Field: bim360FieldIntegrationApi.loginInToBim360Field,

      // Egnyte
      getEgnyteFolders: egnyteIntegrationApi.getEgnyteFolders,
      getEgnyteChildrenFolders: egnyteIntegrationApi.getEgnyteChildrenFolders,
      getEgnyteUserInfo: egnyteIntegrationApi.getEgnyteUserInfo,

      // Forge
      getForgeHubs: forgeIntegrationApi.getForgeHubs,
      getForgeProjects: forgeIntegrationApi.getForgeProjects,
      getForgeUser: forgeIntegrationApi.getForgeUser,
      getForgeFolderInfo: forgeIntegrationApi.getForgeFolderInfo,
      getForgeListFolders: forgeIntegrationApi.getForgeListFolders,
      createForgeIssue: forgeIntegrationApi.createForgeIssue,
      getForgeIssueTypes: forgeIntegrationApi.getForgeIssueTypes,
      getForgeRootCauses: forgeIntegrationApi.getForgeRootCauses,
      clearForgeCache: forgeIntegrationApi.clearForgeCache,
      getForgeIssueCreateContext: forgeIntegrationApi.getForgeIssueCreateContext,

      // OxBlue
      listOxBlueCamerasInfo: oxBlueIntegrationApi.listOxBlueCamerasInfo,
      loginInToOxBlue: oxBlueIntegrationApi.loginInToOxBlue,

      // Storage Service
      listStorageServiceRootFolders: storageServiceIntegrationApi.listRootFolders,
      listStorageServiceSubFolders: storageServiceIntegrationApi.listSubFolders,
      getStorageServiceUser: storageServiceIntegrationApi.getStorageServiceUser,
      getStorageServiceFolderInfo: storageServiceIntegrationApi.getFolderInfo,

      // Aconex
      getAconexUser: aconexIntegrationApi.getAconexUser,
      getAconexProjects: aconexIntegrationApi.getAconexProjects,
      getAconexRegions: aconexIntegrationApi.getAconexRegions,
      getAconexConnection: aconexIntegrationApi.getAconexConnection,

      // StructionSite
      listStructionSiteProjectInfo: structionSiteIntegrationApi.listStructionSiteProjectInfo,
      loginToStructionSiteIntegration: structionSiteIntegrationApi.loginToStructionSiteIntegration,
      enableStructionSiteIntegration: structionSiteIntegrationApi.enableStructionSiteIntegration,

      // Search
      findSearchableEntity: searchApi.findSearchableEntity,
      findSystemSearchableEntity: searchApi.findSystemSearchableEntity,
      searchAssets: searchApi.searchAssets,
      searchAssetsCount: searchApi.searchAssetsCount,
      searchAssetsGroups: searchApi.searchAssetsGroups,
      getSearchedProjects: searchApi.getSearchedProjects,
      findSearchProjectTags: searchApi.findSearchProjectTags,
      getQuickSearch: searchApi.getQuickSearch,
      getRecentSearches: searchApi.getRecentSearches,
      searchOrgUsers: searchApi.searchOrgUsers,
      searchProjectUsers: searchApi.searchProjectUsers,
      deepSearchOrganizationUsersByEmailForModule: searchApi.deepSearchOrganizationUsersByEmailForModule,
      searchOrgUsersByEmailForModule: searchApi.searchOrgUsersByEmailForModule,
      searchProjectUsersByEmailForModule: searchApi.searchProjectUsersByEmailForModule,

      // admin
      scheduleCopyDemoProjectForAllUsers: adminApi.scheduleCopyDemoProjectForAllUsers,
      ensureAdminMode: adminApi.ensureAdminMode,

      // Feature Settings
      getAllFeatureSettingsForOwnerTypeAndFeatureType:
        featureSettingsApi.getAllFeatureSettingsForOwnerTypeAndFeatureType,
      saveFeatureSettings: featureSettingsApi.saveFeatureSettings,
      getFeatureSettingsForOwnerTypeAndOwnerIdAndFeatureType:
        featureSettingsApi.getFeatureSettingsForOwnerTypeAndOwnerIdAndFeatureType,

      // Reporting
      generateSafetySummaryReport: reportingApi.generateSafetySummaryReport,
      generatePhotoReport: reportingApi.generatePhotoReport,
      updatePhotoReport: reportingApi.updatePhotoReport,
      generateFilesInfoReport: reportingApi.generateFilesInfoReport,
      updateSafetySummaryReport: reportingApi.updateSafetySummaryReport,
      getReportSystemData: reportingApi.getReportSystemData,
      updateReportingScheduleData: reportingApi.updateReportingScheduleData,
      getLastExecutedPhotoReportParameters: reportingApi.getLastExecutedPhotoReportParameters,
      rerunLastExecutedPhotoReport: reportingApi.rerunLastExecutedPhotoReport
    }
  })
