/* global angular, _, jQuery */

angular.module('smartvid').service('modal', function ($rootScope, $compile, $timeout, $templateCache, utils, $q) {
  class Modal {
    constructor () {
      this._open = false

      this.createProject = {
        templateUrl: 'modals/create-project.html',
        className: 'create-project',
        header: 'directives.createProject.add'
      }

      this.inviteProjectMembers = {
        templateUrl: 'modals/invite-project-members.html',
        className: 'invite-project-members',
        header: 'directives.inviteProjectMembers.add'
      }

      this.fileUpload = {
        templateUrl: 'modals/file-upload.html',
        className: 'file-upload',
        header: 'directives.fileuploadmodal.addFiles'
      }

      this.exportAssets = {
        templateUrl: 'modals/export-assets.html',
        className: 'export-assets',
        header: 'directives.exportAssets.header'
      }

      this.shareAsset = {
        templateUrl: 'modals/share-asset.html',
        className: 'share-asset',
        header: 'sharing.modalHeader'
      }

      this.shareSearch = {
        templateUrl: 'modals/share-search.html',
        className: 'share-search',
        header: 'sharing.search.modalHeader'
      }

      this.addTag = {
        templateUrl: 'modals/add-tag.html',
        className: 'add-tag',
        header: 'tags.modal.header'
      }

      this.tagImport = {
        templateUrl: 'modals/tag-import.html',
        className: 'tag-import',
        header: 'tags.modal.header-import'
      }

      this.tagImportReport = {
        templateUrl: 'modals/tag-import-report.html',
        className: 'tag-import-report',
        header: 'tags.modal.header-import'
      }

      this.moveTags = {
        templateUrl: 'modals/move-tags.html',
        className: 'move-tags',
        header: 'tags.modal.header-move'
      }

      this.addTagToAsset = {
        templateUrl: 'modals/add-tag-to-asset.html',
        className: 'add-tag-to-asset',
        header: 'tags.modal.header-add-tag-to-asset'
      }

      this.alert = {
        templateUrl: 'modals/alert.html',
        className: 'alert',
        header: 'alert'
      }

      this.vinnieLimitsNotification = {
        templateUrl: 'modals/vinnie-limits-notification.html',
        className: 'alert',
        header: 'admin.vinnieLimits.popupTitle'
      }

      this.deleteConfirm = {
        templateUrl: 'modals/delete-confirm.html',
        className: 'delete-confirm'
      }

      this.projectDeleteConfirm = {
        templateUrl: 'modals/delete-confirm.html',
        className: 'delete-confirm'
      }

      this.projectRestoreConfirm = {
        templateUrl: 'modals/restore-confirm.html',
        className: 'delete-confirm'
      }

      this.projectPermanentlyDeleteConfirm = {
        templateUrl: 'modals/permanently-delete-confirm.html',
        className: 'delete-confirm'
      }

      this.exportAssetsWithTagCountsNoSelection = {
        templateUrl: 'modals/export-assets-with-tag-counts-no-selection.html',
        className: 'delete-confirm'
      }

      this.forceDeleteConfirm = {
        templateUrl: 'modals/force-delete-confirm.html',
        className: 'delete-confirm'
      }

      this.tagDefDeleteConfirm = {
        templateUrl: 'modals/tag-def-delete-confirm.html',
        className: 'delete-confirm'
      }

      this.tagDefDeleteNotPermitted = {
        templateUrl: 'modals/tag-def-delete-not-permitted.html',
        className: 'delete-confirm'
      }

      this.downloadTooManySelectedConfirm = {
        templateUrl: 'modals/download-too-many-selected-confirm.html',
        className: 'download-confirm'
      }

      this.unInviteProjectMember = {
        templateUrl: 'modals/uninvite-project-member-confirm.html',
        className: 'delete-confirm'
      }

      this.projectStatusActivation = {
        templateUrl: 'modals/project-status-activation-confirm.html',
        className: 'activate-confirm',
        header: 'directives.projectProfile.activateProject',
        message: 'activationProjectStatusModal.message'
      }
      this.projectStatusDeactivation = {
        templateUrl: 'modals/project-status-deactivation-confirm.html',
        className: 'deactivate-confirm',
        header: 'directives.projectProfile.deactivateProject',
        message: 'deactivationProjectStatusModal.message'
      }

      this.unInviteAllMembers = {
        templateUrl: 'modals/uninvite-project-member-confirm.html',
        className: 'delete-confirm'
      }

      this.reRunSmartTaggingConfirm = {
        templateUrl: 'modals/rerunsmarttagging-confirm.html',
        className: 'rerunsmarttagging-confirm'
      }

      this.moveAssetConfirm = {
        templateUrl: 'modals/moveassets-confirm.html',
        className: 'alert',
        header: 'common.warning'
      }

      this.moveAssetModal = {
        templateUrl: 'modals/move-asset-modal.html',
        className: 'move-asset-modal',
        header: 'moveAssetModal.header'
      }

      this.createOrganization = {
        templateUrl: 'modals/create-organization.html',
        className: 'create-organization',
        header: 'directives.createOrganization.add'
      }

      this.editOrganization = {
        templateUrl: 'modals/create-organization.html',
        className: 'create-organization',
        header: 'directives.editOrganization.edit'
      }

      this.createUser = {
        templateUrl: 'modals/create-user.html',
        className: 'create-user',
        header: 'directives.createUser.add'
      }

      this.connectToOxBlue = {
        templateUrl: 'modals/connect-to-ox-blue.html',
        className: 'connect-to-oxblue',
        header: 'integrations.oxblue.connection.login_title'
      }

      this.runReport = {
        templateUrl: 'modals/run-report-modal.html',
        className: 'run-report',
        header: 'reporting.title'
      }

      this.editReport = {
        templateUrl: 'modals/run-report-modal.html',
        className: 'run-report',
        header: 'reporting.editReport'
      }

      this.reRunReport = {
        templateUrl: 'modals/rerun-report.html',
        className: 'run-report',
        header: 'reporting.title'
      }
      this.reportingScheduleUnsubscribeConfirmation = {
        templateUrl: 'modals/reporting-schedule-unsubscribe-confirmation.html',
        className: 'reporting-schedule-unsubscribe-confirmation',
        header: 'reporting.schedule.unsubscribe.confirmationTitle'
      }

      this.inactiveOrganizationNotification = {
        templateUrl: 'modals/inactive-organization-notification.html',
        className: 'alert',
        header: 'admin.inactiveOrganization.popupTitle'
      }

      this.projectsExistWarning = {
        templateUrl: 'modals/projects-exist-warning.html',
        className: 'alert',
        header: 'admin.projectsExistWarning.popupTitle'
      }
      this.removeProjectGroup = {
        templateUrl: 'modals/remove-project-group.html',
        className: 'remove-project-group',
        header: 'admin.removeProjectGroup.popupTitle'
      }
      this.integrationStructionSiteLogin = {
        templateUrl: 'modals/connect-to-struction-site.html',
        className: 'connect-to-structionsite',
        header: 'integrations.structionSite.loginModal.title'
      }
    }

    createScope (modalName, scope = {}) {
      let modal = this[modalName] // grab the modal details (assigned in constructor)
      delete this.scope // clears out the old scope (very important)

      if (modalName === 'exportAssetsWithTagCountsNoSelection') {
        modal.header = 'common.whoops'
      }

      if (modalName === 'downloadTooManySelectedConfirm') {
        modal.header = 'common.warning'
      }

      if (modalName === 'shareAsset') {
        if (scope.tag) {
          modal.header = 'sharing.tagModalHeader'
        } else if (scope.comment) {
          modal.header = 'sharing.commentModalHeader'
        } else {
          modal.header = 'sharing.modalHeader'
        }
      }

      if (modalName === 'unInviteProjectMember') {
        if (!scope.hasSelections) {
          modal.header = 'common.whoops'
          modal.message = 'unInviteProjectMemberModal.no_selection'
          modal.message2 = null
        } else {
          modal.header = 'common.warning'
          modal.message = 'unInviteProjectMemberModal.message'
          modal.message2 = 'unInviteProjectMemberModal.message2'
        }
      }

      if (modalName === 'unInviteAllMembers') {
        if (!scope.hasSelections) {
          modal.header = 'common.whoops'
          modal.message = 'unInviteAllProjectMembersModal.no_selection'
          modal.message2 = null
        } else {
          modal.header = 'common.warning'
          modal.message = 'unInviteAllProjectMembersModal.message'
          modal.message2 = 'unInviteAllProjectMembersModal.message2'
        }
      }

      if (modalName === 'forceDeleteConfirm') {
        modal.header = 'common.warning'
        modal.message = 'common.confirm.delete.message'
        modal.message2 = 'common.confirm.delete.message2'
      }

      if (modalName === 'exportAssetsWithTagCounts') {
        modal.header = 'common.whoops'
      }

      if (modalName === 'projectStatusActivation' || modalName === 'projectStatusDeactivation') {
        modal.projectName = scope.projectName
      }
      if (modalName === 'deleteConfirm') {
        if (!scope.hasSelections) {
          modal.header = 'common.whoops'
          modal.message = 'common.confirm.delete.no_selection'
          modal.message2 = null
        } else if (scope.deleteIsProhibited) {
          modal.header = 'common.warning'
          modal.message = 'common.confirm.delete.no_permission'
          modal.message2 = null
        } else {
          modal.header = 'common.warning'
          modal.message = 'common.confirm.delete.message'
          modal.message2 = null
        }
      }

      if (modalName === 'projectDeleteConfirm') {
        if (!scope.hasSelections) {
          modal.header = 'common.whoops'
          modal.message = 'common.confirm.delete.no_selection'
          modal.message2 = null
        } else if (scope.deleteIsProhibited) {
          modal.header = 'common.warning'
          modal.message = 'common.confirm.delete.no_permission'
          modal.message2 = null
        } else {
          modal.header = 'project.confirm.delete.header'
          modal.message = 'project.confirm.delete.message'
          modal.message2 = null
        }
      }

      if (modalName === 'projectPermanentlyDeleteConfirm') {
        if (!scope.hasSelections) {
          modal.header = 'common.whoops'
          modal.message = 'common.confirm.delete.no_selection'
          modal.message2 = null
        } else if (scope.deleteIsProhibited) {
          modal.header = 'common.warning'
          modal.message = 'common.confirm.delete.no_permission'
          modal.message2 = null
        } else {
          modal.header = 'project.confirm.permanentlyDelete.header'
          modal.message = 'project.confirm.permanentlyDelete.message'
          modal.message2 = null
        }
      }

      if (modalName === 'projectRestoreConfirm') {
        if (!scope.hasSelections) {
          modal.header = 'common.whoops'
          modal.message = 'common.confirm.delete.no_selection'
          modal.message2 = null
        } else if (scope.deleteIsProhibited) {
          modal.header = 'common.warning'
          modal.message = 'common.confirm.delete.no_permission'
          modal.message2 = null
        } else {
          modal.header = 'project.confirm.restore.header'
          modal.message = 'project.confirm.restore.message'
          modal.message2 = 'project.confirm.restore.message2'
        }
      }

      if (modalName === 'tagDefDeleteConfirm') {
        modal.header = 'common.warning'
        modal.message = 'tagDefDeleteModal.message'
        modal.message2 = 'tagDefDeleteModal.message2'
        modal.message3 = 'tagDefDeleteModal.message3'
        modal.message4 = 'tagDefDeleteModal.message4'
      }

      if (modalName === 'tagDefDeleteNotPermitted') {
        modal.header = 'common.warning'
        modal.message = 'tags.modal.deletionNotPermittedMessage'
      }

      if (modalName === 'reRunSmartTaggingConfirm') {
        if (!scope.hasSelections) {
          modal.header = 'common.whoops'
          modal.message = 'common.confirm.rerunsmarttagging.no_selection'
          modal.hint = 'common.confirm.rerunsmarttagging.no_selection_hint'
          modal.message2 = null
          modal.message3 = null
        } else {
          modal.header = 'common.warning'
          modal.message = 'common.confirm.rerunsmarttagging.message'
          modal.message2 = 'common.confirm.rerunsmarttagging.message2'
          modal.message3 = 'common.confirm.rerunsmarttagging.message3'
        }
      }

      if (modalName === 'moveAssetConfirm') {
        if (!scope.hasSelections) {
          modal.header = 'common.whoops'
          modal.message = 'common.confirm.moveassets.no_selection'
          modal.hint = 'common.confirm.moveassets.no_selection_hint'
          modal.message2 = null
          modal.message3 = null
        } else {
          modal.header = 'common.warning'
          modal.message = 'common.confirm.moveassets.message'
        }
      }

      let modalClone = _.clone(modal)
      _.assign(modalClone, scope) // apply the passed in scope
      this.scope = _.assign($rootScope.$new(), modalClone) // make our modal object into a scope
    }

    injectModal (modal) {
      let markup = $templateCache.get('modals/modal.html') // grab the modal wrapper template
      let link = $compile(markup) // get a link function back from the $compile service
      let html = link(this.scope) // link the scope to the template

      // inject into the container (check index.html) and blow away previous modal
      // TODO: worry about things which may need to be destroyed or unbound or had other references
      jQuery('.modal-container').html(html)

      // bind keypress here for closing modal with escape.
      // TODO: possibly move it out of jQuery and add common KEYs constants lookup.
      let ESCAPE_KEY = 27
      let handler = (e) => {
        if (e.which === ESCAPE_KEY && this.isOpen) {
          this.close()
          jQuery('body').off('keyup', handler)
        }
      }
      jQuery('body').on('keyup', handler)
    }

    open (modalName, scope) {
      this.createScope(modalName, scope)
      this.injectModal()
      this.isOpen = true
      utils.digest(this.scope)
    }

    close () {
      let defer = $q.defer()
      this.isOpen = false
      utils.digest(this.scope)

      defer.resolve(this.isOpen)

      if (this.scope.callback) {
        this.scope.callback(this.scope)
      }
      return defer.promise
    }

    get isOpen () {
      return this._open
    }

    set isOpen (openState) {
      this._open = openState
      utils.digest(this.scope)
    }

    get templateUrl () {
      return this.scope.templateUrl || ''
    }

    get controller () {
      return this.scope.controller
    }
  }

  let modal = new Modal()
  return modal // return our class instance
})
