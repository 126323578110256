/* global angular _ */

angular.module('smartvid').factory('Bim360FieldSyncSettingsModel', function (BaseModel) {
    class Bim360FieldSyncSettingsModel extends BaseModel {
      constructor (attrs) {
        let defaults = {
          partnerUserId: attrs && attrs.bim360FieldUserId ? attrs.bim360FieldUserId : undefined,
          bim360FieldUserId: undefined,
          isDownloadLocations: undefined,
          lastSyncDate: undefined
        }
  
        super(_.defaults(attrs || {}, defaults))
      }
    }
  
    return Bim360FieldSyncSettingsModel
  })
  
  